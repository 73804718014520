import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { appendScript } from "../Util/script/appendScript";

const Header=()=>{



    const handleDropdown=(e)=>{
        var element =  e.target.nextSibling
        if(element.style.display==="block"){
            element.style.display="none"
        }else{
            element.style.display="block"
        }
    }


    return (

        
        <header className="main-header header-three">
      
       
        <div className="header-upper">
        <div className="container clearfix">
        <div className="header-inner py-20">
        <div className="logo-outer">
        <div className="logo"><Link to="/">
        <img src="assets/images/logos/logo.png" width="165" height="50" alt="logo"/></Link>
           {/* &nbsp; <span className="App-logo" style={{color: "#1357BE"}}><b>ARAKNERD</b></span> */}
        </div>
        </div>
        <div className="nav-outer d-flex align-items-center clearfix mx-lg-auto">

        <nav className="main-menu navbar-expand-lg">
        <div className="navbar-header">
        <div className="logo-mobile"><Link to="/"><img src="assets/images/logos/logo-two.png" alt="Logo" width="70px"/></Link></div>

        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="main-menu">
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        </button>
        </div>
        <div className="navbar-collapse collapse clearfix" id="main-menu">
        <ul className="navigation clearfix">
        <li><Link to="/">home</Link></li>
        <li><Link to="/about">about</Link></li>


        <li className="dropdown">
            <Link to="#" onClick={handleDropdown}>Products</Link>
        
        <ul>
        <li>
        <div className="row my-3" style={{width: "800px", height: "400px"}} >
        <div className="col-md-6 col-xl-3 mb-2 mb-xl-0">
            
            {/* <h5>Image</h5> */}
                {/* <img src="assets/images/docs/techbg/programming7.png" style={{height: "150px"}} alt="dropdwon"/> */}
                <div className="card-group">
            <div className="card" style={{backgroundColor: "orange", border: "none"}}>

                <img src="assets/images/docs/techbg/programming7.png" alt="pro"/>
              <div className="card-body">
              <p className="card-text" style={{color: "white"}}>Try our products</p>
              </div>
            </div>
            </div>
           
        </div>
            <div className="col-md-6 col-xl-3 mb-2 mb-xl-0">
            
            <h5 style={{color: "#1357BE"}}>Products</h5>
                <Link to="/yexp" className="dropdown-item">YEXP Accounting</Link>
                <Link to="/eschool" className="dropdown-item">Eschool</Link>
                <Link to="/epos" className="dropdown-item">Epos</Link>
                <Link to="/quickpost" className="dropdown-item">QuickPost</Link>
         
            
            </div>
            <div className="col-md-6 col-xl-3 mb-2 mb-xl-0">
            
            <h5 style={{color: "#1357BE"}}>Features</h5>
                <Link to="#" className="dropdown-item">Manage Bills</Link>
                <Link to="#" className="dropdown-item">Track income & expenses</Link>
                <Link to="#" className="dropdown-item">Invoice customers</Link>
                <Link to="" className="dropdown-item">Run Reports</Link>
                <Link to="" className="dropdown-item">Track Inventory</Link>
          
                
            </div>
            <div className="col-md-6 col-xl-3 mb-2 mb-xl-0">
            
            <h5 style={{color: "#1357BE"}}>By Type</h5>
                <Link to="#" className="dropdown-item">Small Business</Link>
                <Link to="#" className="dropdown-item">Medium-size business</Link>
                <Link to="#" className="dropdown-item">New business </Link>
                <Link to="" className="dropdown-item">Supermarket</Link>
                <Link to="" className="dropdown-item">School</Link>
          
                
            </div>

           
            
        </div>
      
        </li>

        </ul>
        </li>
        <li><Link to="/pricing">Pricing</Link></li>


        
        
        {/* <li className="dropdown"><Link to="#">blog</Link>
        <ul>
        <li><Link to="blog.html">blog standard</Link></li>
        <li><Link to="blog-details.html">blog details</Link></li>
        </ul>
        </li>
        <li className="dropdown"><Link to="#">pages</Link>
        <ul>
        <li><Link to="single-service.html">service single</Link></li>
        <li><Link to="team-details.html">Team Profile</Link></li>
        </ul>
        </li> */}
        <li><Link to="/privacy">Privacy</Link></li>
        <li><Link to="/contact">contact</Link></li>
       
        </ul>
        </div>
        </nav>


      
        </div>
        <div className="menu-right d-none d-lg-flex align-items-center">
        <Link to="#" className="login">Login <i className="fas fa-lock"></i></Link>
        <Link to="#" className="theme-btn style-two">sign up <i className="fas fa-arrow-right"></i></Link>
        </div>
        </div>
        </div>
        </div>
        

        </header>

    )
}

export default Header;