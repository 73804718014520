import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'

const Pricing = ()=> {
  return (
    
    <div className='page-wrapper'>
    <Header />
    <section className="pricing-section rel z-1  pt-130 rpt-100 pb-100 rpb-70">
      <div className="container">
        <div className="row justify-content-center">
        <div className="col-xl-7 col-lg-8 col-md-10">
        <div className="section-title text-center text-white mb-45">
        <span className="sub-title">Pricing of our Solutions</span>
        <h4 style={{color: "black"}}>Plans that fit your business</h4>
        </div>
        </div>
        </div>
        <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6">
        <div className="pricing-item wow fadeInUp delay-0-2s">
        <h5 className="price-title">YEXP</h5>
        <span className="price">35</span>
        <p>Accounting System: For all institutions including schools.</p>
        <a href="#" className="theme-btn">Choose product <i className="fas fa-long-arrow-alt-right"></i></a>
        <ul className="list-style-one">
        <li>30-day Free Trial Plan</li>
        <li>School fees management</li>
        <li>Supplier tracking</li>
        <li>Invoicing</li>
        <li>Balance sheet</li>
        <li>Unlimited user accounts</li>
        <li>24/7 Support</li>
        </ul>
        </div>
        </div>
        <div className="col-lg-4 col-md-6">
        <div className="pricing-item wow fadeInUp delay-0-4s">
        <h5 className="price-title">Eschool</h5>
        <span className="price">49.85</span>
        <p>School Management system: Other payment plans can apply.</p>
        <a href="#" className="theme-btn">Choose Product <i className="fas fa-long-arrow-alt-right"></i></a>
        <ul className="list-style-one">
        <li>30-day Free Trial Plan</li>
        <li>New Curriculum reports</li>
        <li>Student tracking</li>
        <li>Staff tracking</li>
        <li>Timetabling</li>
        <li>Unlimited user accounts</li>
        </ul>
        </div>
        </div>
        <div className="col-lg-4 col-md-6">
        <div className="pricing-item wow fadeInUp delay-0-6s">
        <h5 className="price-title">Epos</h5>
        <span className="price">22</span>
        <p>Point of sale and Inventory management software.</p>
        <a href="#" className="theme-btn">Choose Product <i className="fas fa-long-arrow-alt-right"></i></a>
        <ul className="list-style-one">
        <li>30-day Free Trial Plan</li>
        <li>Unique Design</li>
        <li>24/7 Supports</li>
        <li>Supplier Tracking</li>
        <li>Stock Alerts</li>
        <li>Customer Counter Pausing</li>
        <li>Real time dashboard</li>
        </ul>
        </div>
        </div>
        </div>
        </div>
        <div className="section-alpha-text">Our Pricing Plan</div>
        <img className="dots-shape" src="assets/images/shapes/white-dots.png" alt="Shape"/>
        <img className="tringle-shape" src="assets/images/shapes/tringle.png" alt="Shape"/>
        <img className="close-shape" src="assets/images/shapes/yellow-close.png" alt="Shape"/>
        <div className="left-circles"></div>
        <div className="right-circles"></div>
      </section>
   
      
  <Footer/>
    </div>
   
  )
}

export default Pricing