import React, { useEffect, useState } from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import dictionary from '../Util/dictionary';
import { loadScripts } from '../Util/script/appendScript';

const About=(props)=>{


    return (
        <div className="page-wrapper">
            <Header/>
            <img src="assets/images/docs/office/P5.jpg" alt="About"/>
           
            <section className="about-page-section rel z-1 pt-130 rpt-100">
            
            <div className="container">
            <div className="row align-items-center">
            <div className="col-xl-5 col-lg-6">
            <div className="about-page-content rmb-65 wow fadeInLeft delay-0-2s">
            <div className="section-title mb-25">
            {/* <span className="sub-title">About Araknerd</span> */}
            <h2>About Us</h2>
            </div>
            <p>
                <b style={{fontSize: "32px"}}>A</b>raknerd is a top-tier software development company that started in 2017 with a vision of 
                creating modern digital solutions for customers. We help clients create custom software that improves their operations and opens new markets.
                </p>
                <p>Our team delivers high-value, high-quality solutions that our clients depend on, and lifetime partnerships they believe in.</p>
           
            
            </div>
            </div>
            <div className="col-lg-6">
            <div className="fadeInRight delay-0-2s">
            {/* <img src="assets/images/docs/techbg/pro6.png" alt="About"/> */}
            {/* <img src="assets/images/docs/techbg/programmer2.jpg" alt="About"/> */}
            {/* <img src="assets/images/docs/office/P1.jpg" alt="About"/> */}
            <img src="assets/images/docs/techbg/programming2.jpg" alt="About"/>
           
            </div>
            </div>
            </div>
           
            </div>
            </section>
            <section className="about-page-section rel z-1 pt-130 rpt-100">
            <div className="container">
            <div className="row align-items-center">
            
            <div className="col-lg-6">
            <div className="fadeInRight delay-0-2s">
           
            <img src="assets/images/docs/office/P1.jpg" alt="About"/>
            
           
            </div>
            </div>
            <div className="col-xl-5 col-lg-6">
            <div className="about-page-content rmb-65 wow fadeInLeft delay-0-2s">
            <div className="section-title mb-25">
            {/* <span className="sub-title">About Araknerd</span> */}
            <h2>AraknerdLIB</h2>
            </div>
            <p>
                <b style={{fontSize: "32px"}}>A</b>{dictionary.araknerdLibText}
                </p>
                <p>{dictionary.araknerdLibText2}</p>
           
            
            </div>
            </div>
            </div>
            </div>
            </section>
            
            

            <section className="feedback-section-three bg-blue rel z-1 py-115 rpy-85" style={{backgroundImage: "url(assets/images/docs/techbg/programming3.jpg)"}}>
            <div className="container">
            <div className="row">
            <div className="col-xl-4">
            <div className="feedback-three-content mt-15 text-white wow fadeInUp delay-0-2s">
            <div className="section-title mb-25">
            <span className="sub-title">Clients Feedback</span>
            <h2>What Our Customers Say About Us</h2>
            </div>
            {/* <p>Sed ut perspiciatis unde omnis natus error voluptatem accusantium doloremque laudantium totam rem aperias eaque quae ainvtore veritatis et quasi architecto beatae vitae dicta sunt explicabo enim spsam</p> */}
            <div className="slider-dots-area mt-25"></div>
            </div>
            </div>
            {/* <div className="col-xl-8">
            <div className="feedback-three-wrap">
            <div className="feedback-item-two wow fadeInUp delay-0-4s">
            <img src="assets/images/client-logos/client-logo1.png" alt="Logo"/>
            <p>Sed ut perspiciatis unde omniste natus errors voluptatem accusantium doloreue laudantium <b>eaque ipsauae abtore</b> verita architecto beatae vitae dicta sunxplica nemo enimsam voluptatem quia natur autodit aut fugit</p>
            <div className="feedback-author">
            <i className="flaticon-quote-1"></i>
            <div className="author-content">
            <h3>Randall L. Hamby</h3>
            <span>Senior Manager</span>
            </div>
            </div>
            </div>
            <div className="feedback-item-two wow fadeInUp delay-0-6s">
            <img src="assets/images/client-logos/client-logo2.png" alt="Logo"/>
            <p>Sed ut perspiciatis unde omniste natus errors voluptatem accusantium doloreue laudantium <b>eaque ipsauae abtore</b> verita architecto beatae vitae dicta sunxplica nemo enimsam voluptatem quia natur autodit aut fugit</p>
            <div className="feedback-author">
            <i className="flaticon-quote-1"></i>
            <div className="author-content">
            <h3>Lillie R. Burkhalter</h3>
            <span>Senior Manager</span>
            </div>
            </div>
            </div>
            <div className="feedback-item-two wow fadeInUp delay-0-8s">
            <img src="assets/images/client-logos/client-logo3.png" alt="Logo"/>
            <p>Sed ut perspiciatis unde omniste natus errors voluptatem accusantium doloreue laudantium <b>eaque ipsauae abtore</b> verita architecto beatae vitae dicta sunxplica nemo enimsam voluptatem quia natur autodit aut fugit</p>
            <div className="feedback-author">
            <i className="flaticon-quote-1"></i>
            <div className="author-content">
            <h3>Zelma K. Hall</h3>
            <span>Senior Manager</span>
            </div>
            </div>
            </div>
            </div>
            </div> */}

            </div>
            </div>
            </section>


            <Footer/>
        </div>
    );
}

export default About;