import React from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import { Link } from 'react-router-dom';
import Solutions from '../Components/Sections/Solutions';
import dictionary from '../Util/dictionary';


const Home=(props)=>{
    return (
        <div className="page-wrapper">
            <Header/>
            <section className="hero-section-three rel z-2 pt-130 rpt-100 pb-130 rpb-100">
            <div className="container">
            <div className="row align-items-center">
            <div className="col-lg-6 col-md-11">
            <div className="hero-content-three rpt-15 rmb-75">
            <h1 className="mb-15 wow fadeInUp delay-0-2s">Smarter solutions. <br/> Happier clients.<br/>Better results.</h1>
            <p className="wow fadeInUp delay-0-4s">{dictionary.heroText}</p>
            
            </div>
            <Link to="/about" className="theme-btn style-three">About US <i className="fas fa-arrow-right"></i></Link>
            </div>
            <div className="col-lg-6">
            <div className="hero-image-three overlay wow fadeInLeft delay-0-4s">
            <img src="assets/images/docs/office/P2.jpg" alt="Hero"/>
            </div>
            </div>
            </div>
            </div>
            <img className="dots-shape" src="assets/images/shapes/dots.png" alt="Shape"/>
            <img className="tringle-shape" src="assets/images/shapes/tringle.png" alt="Shape"/>
            <img className="close-shape" src="assets/images/shapes/close.png" alt="Shape"/>
            </section>
            <h2 className="text-center">Our Services</h2>
            <section className="single-servcie-section rel z-1 py-130 rpy-100">
           
                <div className="container">
                <div className="row">
                <div className="col-xl-4 col-lg-5">
                <div className="service-sidebar rmb-75">
                <div className="service-widget service-list wow fadeInUp delay-0-2s">
                <ul>
               
                {/* <li><a href="single-service.html">Systems Development <i className="fas fa-angle-right"></i></a></li> */}
                {/* <li><a href="single-service.html">Mobile Application Development <i className="fas fa-angle-right"></i></a></li> */}
                <li className=""><a href="single-service.html">Software Development <i className="fas fa-angle-right"></i></a></li>
                <li><a href="#">Computer Networking  <i className="fas fa-angle-right"></i></a></li>
                <li><a href="#">Web Hosting <i className="fas fa-angle-right"></i></a></li>
                <li><a href="#">Developer Training <i className="fas fa-angle-right"></i></a></li>
                <li><a href="#">Process Modelling <i className="fas fa-angle-right"></i></a></li>
                <li><a href="#">Barcode Solutions<i className="fas fa-angle-right"></i></a></li>
                <li><a href="#">RFID Solutions<i className="fas fa-angle-right"></i></a></li>

                </ul>
                </div>
                <div className="service-widget call-action-widget wow fadeInUp delay-0-2s">
                <h3>Need Any</h3>
                <span className="h2">Consultation</span>
                <div className="action-btn"><Link to="/contact" className="theme-btn">contact us <i className="fas fa-arrow-right"></i></Link></div>
                <a className="number" href="callto:+256704722190">+256 (704) 722190</a>
                <img className="action-man" src="assets/images/hero/" alt="Call To Action"/>
                <img className="dots-shape slideUpDown" src="assets/images/shapes/white-dots-two.png" alt="Shape"/>
                <img className="circle-shape slideUpRight" src="assets/images/shapes/circle.png" alt="Shape"/>
                </div>
                <div className="service-widget file-download wow fadeInUp delay-0-2s">
                <ul>
                <li>
                <a href="#">
                <img src="assets/images/logos/google.jpg" alt="google" style={{width: 40, height: 40}}/>
                <h5>Download QuickPost App</h5>
                </a>
                </li>
                <li>
              
                </li>
                </ul>
                </div>
                <img src="assets/images/docs/techbg/programming7.png" alt="programming"/>
                </div>
                {/* <img src="assets/images/docs/techbg/programming.jpg" alt="programming"/> */}
                </div>
                <div className="col-xl-8 col-lg-7">
                <div className="single-service-content">
                <div className="image mb-40 wow fadeInUp delay-0-4s">
                <img src="assets/images/services/service.png" alt="Service"/>
                </div>
                <h2>{dictionary.fullCoName}</h2>
                <p>{dictionary.servicesText}</p>
                <div className="row mt-55">
                <div className="col-xl-4 col-sm-6">
                <div className="service-item-two wow fadeInUp delay-0-2s">
                <i className="flaticon-data-analysis"></i>
                <h3><a href="single-service.html">Accounting Software</a></h3>
                <p>
                <Link to="/yexp" style={{color: "purple"}}><b>Read more </b></Link></p>
                </div>
                </div>
                <div className="col-xl-4 col-sm-6">
                <div className="service-item-two wow fadeInUp delay-0-4s">
                <i className="flaticon-monitoring"></i>
                <h3><a href="single-service.html">School Management Software</a></h3>
                <p>
                <Link to="/eschool" style={{color: "green"}}><b>Read more </b></Link>
                </p>
                </div>
                </div>
                <div className="col-xl-4 col-sm-6">
                <div className="service-item-two wow fadeInUp delay-0-6s">
                <i className="flaticon-fast-delivery"></i>
                <h3><a href="single-service.html">Point of Sale Software</a></h3>
                <p>
                <Link to="/epos" style={{color: "blue"}}><b>Read more </b></Link></p>
                </div>
                </div>
                </div>
                {/* <h2></h2> */}
                <p>
                All our software is accessible to any device with a network connection, typically through the web browser.
                 You do not have to go through the hustle of setting up or even maintenance. When new functionalities or features are released, 
                 we roll them out to our customers depending on the Service Level Agreement.
                 
                </p>
                <h6><b>Some of our users include;</b></h6>
                <ul className="list-style-one">
                <li>Primary and Secondary Schools</li>
                <li>Supermarkets and other stock taking businesses</li>
                <li>Organizations that follow a cash-based accounting standard.</li>
                </ul>
                </div>
                </div>
                </div>
                </div>
            </section>

            <Solutions/>
           

            <Footer/>
        </div>
    );
}

export default Home;