import React from "react";

const Solutions = ()=>{
    return(
    <section className="solutions-section-three text-white text-center rel bg-blue pt-130 rpt-100 z-1 pb-75 rpb-45" style={{backgroundMmage: "url(assets/images/shapes/solutions-bg-dots.png)"}}>
<div className="container">
<div className="row justify-content-center">
<div className="col-xl-7 col-lg-8 col-md-10">
<div className="section-title mb-75">
<span className="sub-title">Get started</span>
<h2>Why choose Araknerd</h2>
</div>
</div>
</div>
<div className="row justify-content-center">
<div className="col-xl-4 col-md-6">
<div className="solution-item-three wow fadeInUp delay-0-2s">
<h3><a href="single-service.html">Secure </a></h3>
<p>Araknerd systems are the most secure systems with modern security congruence.</p>

</div>
</div>
<div className="col-xl-4 col-md-6">
<div className="solution-item-three wow fadeInUp delay-0-4s">
<h3><a href="single-service.html">Cost Effective</a></h3>
<p>
    Reduce costs on movements and hiring by doing all the work on your device.
</p>

</div>
</div>
<div className="col-xl-4 col-md-6">
<div className="solution-item-three wow fadeInUp delay-0-6s">
<h3><a href="single-service.html">  Fast </a></h3>
{/* <h4>Software as a Service</h4> */}
<p>
    Built with the latest technology, our platforms are fast and convenient for running your business.
</p>

</div>
</div>
</div>
</div>
</section>
    )
}

export default Solutions;