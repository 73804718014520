import React from "react";
import { Link } from "react-router-dom";
import dictionary from "../Util/dictionary";

const Footer=()=>{
    return (
        <footer className="footer-section footer-two bg-gray text-white rel z-1">
        <div className="container">
        <div className="call-to-action bg-blue bgs-cover text-white rel z-1">
        <div className="row align-items-center">
        <div className="col-xl-7 col-lg-6">
        <div className="section-title mb-20">
        <h2>In your corner when you need us</h2>
        <p>Professional service for your software solutions</p>
        </div>
        </div>
        <div className="col-xl-5 col-lg-6">
        <div className="call-to-action-btns text-xl-right mb-20">
        <Link to="/" className="theme-btn style-two rmb-15">Get started <i className="fas fa-arrow-right"></i></Link>
        <Link to="/about" className="theme-btn style-three rmb-15">Learn More <i className="fas fa-arrow-right"></i></Link>
        </div>
        </div>
        </div>
        <img className="white-circle" src="assets/images/shapes/white-circle.png" alt="White Circle"/>
        <img className="white-dots slideUpRight" src="assets/images/shapes/white-dots.png" alt="shape"/>
        <img className="white-dots-circle slideLeftRight" src="assets/images/shapes/white-dots-circle.png" alt="shape"/>
        </div>
        <div className="row justify-content-between">
        <div className="col-xl-3 col-sm-6 col-7 col-small">
        <div className="footer-widget about-widget">
        <div className="footer-logo mb-20">
        <a href="/home">
        <img src="assets/images/logos/logo-bw.png" width="40px" alt="Logo"/>
        <span className="App-logo">{dictionary.companyName}</span>
        </a>
        </div>
        <p><span> 
        We give your clients digital experiences that are human-centered and future proof.</span> </p>
        <Link to="/about" className="read-more">Read More <i className="fas fa-arrow-right"></i></Link>
        </div>
        </div>
        <div className="col-xl-2 col-sm-4 col-5 col-small">
        <div className="footer-widget link-widget">
        <h4 className="footer-title">Products</h4>
        <ul className="list-style-two">
        <li><Link to="/quickpost">Quickpost</Link></li>
        <li><Link to="/yexp">YEXP</Link></li>
        <li><Link to="/eschool">Eschool</Link></li>
        <li><Link to="/epos">Epos</Link></li>
        </ul>
        </div>
        </div>
        <div className="col-xl-2 col-sm-4 col-5 col-small">
        <div className="footer-widget link-widget">
        <h4 className="footer-title">Quick Links</h4>
        <ul className="list-style-two">
        <li><Link to="/products">Software Solutions</Link></li>
        <li><Link to="/pricing">Pricing </Link></li>
        <li><Link to="/privacy">Privacy Policy</Link></li>
     
        
       
        </ul>
        </div>
        </div>
        <div className="col-xl-3 col-md-4">
        <div className="footer-widget contact-widget">
        <h4 className="footer-title">Get in Touch</h4>
        <ul className="list-style-three">
        <li><i className="fas fa-map-marker-alt"></i> <span>Kampala, Uganda</span></li>
        <li><i className="fas fa-envelope-open"></i> <span><a href={"mailto:" + dictionary.email}><span className="__cf_email__">{dictionary.email}</span></a></span></li>
        <li><i className="fas fa-phone"></i> <span>Call : <a href="callto:+256763633294">+256 (763) 633 294</a></span></li>
        </ul>
        <div className="social-style-one mt-25">
        <Link to="#"><i className="fab fa-facebook-f"></i></Link>
        <Link to="#"><i className="fab fa-twitter"></i></Link>
        <Link to="#"><i className="fab fa-linkedin-in"></i></Link>
        <Link to="#"><i className="fab fa-instagram"></i></Link>
        </div>
        </div>
        </div>
        </div>
        <div className="copyright-area text-center">
        <p>© 2022 <a href="index-2.html">{dictionary.companyName}.</a> All rights reserved</p>
        </div>
        </div>
        <img className="dots-shape" src="assets/images/shapes/dots.png" alt="Shape"/>
        <img className="tringle-shape" src="assets/images/shapes/tringle.png" alt="Shape"/>
        <img className="close-shape" src="assets/images/shapes/close.png" alt="Shape"/>
        <img className="circle-shape" src="assets/images/shapes/circle.png" alt="Shape"/>

        <button className="scroll-top scroll-to-target" data-target="html"><span className="fa fa-angle-up"></span></button>
        </footer>
        

        
    )
}

export default Footer;