import React from 'react'
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { Player } from 'video-react';

const Quickpost = ()=>  {
  return (
    <div className='page-wrapper'>
        <Header />
        <section class="browswr-support-section rel z-1 py-130 rpy-100">
        <div class="container">
        <div class="row align-items-center">
        <div class="col-xl-5 col-lg-6">
        <div class="browswr-support-content rmb-55 wow fadeInRight delay-0-2s">
        <div class="section-title">
        <span class="sub-title">QuickPost</span>&nbsp;&nbsp;&nbsp;
        <span><button className="btn btn-outline-dark">Get the App</button></span>
        <h4>With the QuickPost app, you can Borrow instant cash, Send and Receive instant cash.</h4>
        </div>
        <div class="row">
        <div class="col-lg-6">
        <div class="solution-item-two">
        <i class="fas fa-check"></i>
        {/* <h4>Track your Customers From your Website</h4> */}
        <p>QuickPost links money lenders/Agents with their 
        customers using mobile telecommunication and mobile money cashless technology.</p>
        </div>
        </div>
        <div class=" col-lg-6">
        <div class="solution-item-two color-two">
        <i class="fas fa-check"></i>
        {/* <h4>Understand Much Sells Performance Better</h4> */}
        <p>It helps you manage;
        <ol>
        <li> Customers</li>
        <li>Loan processing</li>
        <li>Loan repayment</li>
        <li>Loan management</li>
        <li>Float/capital management</li> 
        </ol> 
        </p>
        
        </div>
        </div>
        </div>
        </div>
        </div>
        <div class="col-md-6">
        <div class="browswr-support-image text-lg-right wow fadeInLeft delay-0-2s">
        <img src="assets/images/products/qppp.png" alt="Browser Support"/>
        </div>
        </div>
        </div>
        </div>


    </section>

    <div className="video">
      <div className="container">
    <video autoPlay loop muted src="assets/images/products/Araknerd.mp4" allow="loop" style={{width: "100%", height: "20%", position: "relative"}} />
    {/* <iframe src="assets/images/products/Araknerd.mp4?autoplay=1" title="demo" allow='autoplay' /> */}
      </div>
    </div>


        <Footer />
    </div>
  );
}

export default Quickpost