import React from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import { Link } from 'react-router-dom';
import dictionary from '../Util/dictionary';


const Contact = ()=> {

  const handleSubmit =(e)=>{
    e.preventDefault();
  }

  return (
    <div className="page-wrapper">
        <Header/>
        
        
        <section className="contact-page-section pt-130 rpt-100 pb-40 rpb-90"  >
        
        {/* <h2 className="text-center" style={{color:"#1357BE"}} >Contact Us</h2> */}
       
          <div className="container">
          
          <div className="contact-content-area wow fadeInLeft delay-0-2s">
          {/* <div className="section-title mb-15">
          <span className="sub-title">contact us</span>
          <h3 style={{alignText: "center"}}>We’re Ready to Help You!</h3>
          </div> */}

          </div>
          <div className="card-group">
          <div className="card" style={{width:"18rem", height:"26rem", border: "none"}} >
               
                  <div className="card-body">
                  <h3 className="card-title">Meet Us</h3>

                  <p className="card-text ">
                  <div className="row pt-25">
                  <div className="col-md-12">
                  <div className="contact-info-item style-two">
                  <i className="fas fa-map-marker-alt"></i>
                  <div className="contact-info-content">
                  <h5>Kampala, Uganda</h5>
                  </div>
                  </div>
                  </div>
                  <div className="col-md-12">
                  <div className="contact-info-item style-two">
                  <i className="fas fa-envelope-open"></i>
                  <div className="contact-info-content">
                  <h5>{dictionary.email}</h5>
                  </div>
                  </div>
                  </div>
                  <div className="col-md-12">
                  <div className="contact-info-item style-two">
                  <i className="fas fa-phone"></i>
                  <div className="contact-info-content">
                  <h5>+256 (763) 63 3294</h5>
                  <h5>+256 (701) 17 9362</h5>
                  </div>
                  </div>
                  </div>


            </div>
                  </p>
                  
                </div>
              </div>
             
           
            <div className="card" style={{border: "none"}} >
            <div className="card-body">
                  <h3 className="card-title">Talk to us</h3>
                  <p className="card-text">
                  {/* <p>Hello</p>
                  My name is <input type="text" value="name" placeholder="your name"/> and my email address is
                  <input type="email" value="email" placeholder="email"/>.Here's my message: 
                  <textarea placeholder="type here"/> */}
                  <form id="contact-page-form" className="contact-form-three pt-35" action="#" method="post" onSubmit={handleSubmit}>
                  <div className="row clearfix">
                
                  <div className="col-sm-12">
                  <div className="form-group">
                  <input type="email" id="email" name="email" className="form-control" placeholder="Email Address" required=""/>
                  </div>
                  </div>

                  <div className="col-md-12">
                  <div className="form-group">
                  <textarea name="message" id="message" rows="4" className="form-control" placeholder="message" required=""></textarea>
                  </div>
                  </div>
                  <div className="col-md-12">
                  <div className="form-group mb-0">
                  <button className="theme-btn" type="submit" >send message </button>
                  </div>
                  </div>
                  </div>
                  </form>
                        </p>
                      
                  </div>
                
              </div>

              <div className="card" style={{backgroundImage: "url('assets/images/contact/map2.png')", backgroundSize: "cover", backgroundRepeat: "no-repeat", border: "none"}}>
                  <img src="assets/images/contact/support2.jpg" alt="support2"/>
                
                </div>
          </div>
              

              </div>

         

        </section>
       
        <div className="section-title text-center mt-130 rmt-100 mb-60">
        <span className="sub-title">GET IN TOUCH WITH US</span>
        <h3>From anywhere around the world</h3>
        </div>
        <section className="page-banner" style={{backgroundImage: "url('assets/images/docs/techbg/programming4.png')", backgroundSize: "cover"}}>
        <div className="card" style={{width: "24rem", height: "18rem", padding: 20, color: "white", backgroundImage: "url('assets/images/contact/man3.png')", backgroundRepeat: "no-repeat", border: "none", marginLeft: "170px"}}>
        
             
               
        </div>
        </section>
        <br/><br/><br/>
        <section>

        </section>

       

       
        {/* <Blue/> */}
        <Footer/>
    </div>
      
  )
}

export default Contact