import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

const Yexp = ()=> {
  return (
    <div className='page-wrapper'>
     <Header />
     <section class="browswr-support-section rel z-1 py-130 rpy-100">
        <div class="container">
        <div class="row align-items-center">
        <div class="col-xl-5 col-lg-6">
        <div class="browswr-support-content rmb-55 wow fadeInRight delay-0-2s">
        <div class="section-title">
        <span class="sub-title">YEXP</span>
        <h3>Accounting Software</h3>
        </div>
        <div class="row">
        {/* <div class="col-md-6"> */}
        <div class="solution-item-two">
        <i class="fas fa-check"></i>
        {/* <h4>Track your Customers From your Website</h4> */}
        <p>This software is best suitable for schools
         and other organisations that follow a cash based accounting standard.</p>
        </div>
      
        {/* </div> */}
        {/* <div class="col-md-6">  */}
        <div class="solution-item-two color-two">
        {/* <i class="fas fa-check"></i>
        <h4>Understand Much Sells Performance Better</h4>
        <p>It is modernised to help you manage and improve business operations.</p> */}
        </div>
        {/* </div> */}
        </div>
        </div>
        </div>
        <div class="col-xl-7 col-lg-6">
        <div class="browswr-support-image text-lg-right wow fadeInLeft delay-0-2s">
        <img src="assets/images/products/yexp.png" alt="Browser Support"/>
        </div>
        </div>
      
        </div>
        </div>
    </section>

   


      <Footer />
    </div>
  )
}

export default Yexp