import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

const Privacy = () => {
  return (
    <div className="page-wrapper rel z-1  pt-130 rpt-100 ">
    <Header/>
         <section id="introduction" style={{paddingBottom: "40px"}}>
        <div class="container" style={{paddingInline: "40px", marginTop: "40px"}}>
            <h3 style={{textAlign: "center", paddingBottom: "30px"}}>Araknerd Privacy Policy</h3>
            <h5>INTRODUCTION</h5>
            <p>When you use our products, you are trusting us with your information. We understand that this is a big responsibility and we work hard to protect your information and put you in control.This Privacy Policy is meant to help you understand what information we collect, why we collect it and how you can update, manage, export and delete your information.</p>
            <p>We build a range of solutions to help businesses manage their transactions better and keep in touch with their customers. Our products include:</p>
           
            <ol className="intro-list" style={{marginLeft: "50px"}}>
                <li>QuickPost Mobile Application</li>
                <li>Eschool and Epos web platform</li>
                <li>YEXP accounting system</li>
            </ol>
        </div>
    </section>
    <section id="information">
        <div class="container" style={{paddingInline: "40px"}}>
            <h5>INFORMATION ARAKNERD COLLECTS</h5>
            <p>We want you to understand the types of information we collect as you use our products.</p>
            <h6>Things you create or provide for us</h6>
            <p>When you create an Araknerd Account, you provide us with personal information that includes your name and a password. You can also choose to add a phone number or payment information to your account. Even if you aren’t signed in to an Araknerd Account, you might choose to provide us with information — 
                like an email address to receive updates about our products.</p>
        </div>
    </section>
    <section id="why-collect-data">
        <div class="container" style={{paddingInline: "40px", marginTop: "40px"}}>
            <h5>WHY ARAKNERD COLLECTS DATA</h5>
            <p>We use data to build better services. We use the information we collect from all our services for the following purposes:</p>
            <ul className="collect-data" style={{marginLeft: "50px"}}>
                <li> <h6>Maintain and improve our services</h6></li>
                <p>We also use your information to ensure our products are working as intended, such as tracking outages or troubleshooting issues that you report to us. And we use your information to make improvements to our products.</p>
                <li> <h6>Develop new services</h6> </li>
                <p>We use the information we collect in existing products to help us develop new ones.</p>
                <li> <h6>Measure performance</h6> </li>
                <p>We use data for analytics and measurement to understand how our products are used.</p>
                <li> <h6>Communicate with you</h6> </li>
                <p>We use information we collect, like your email address, to interact with you directly. For example, we may send you a notification if we detect suspicious activity, like an attempt to sign in to your Araknerd Account from an unusual location. Or we may let you know about upcoming changes or improvements to our products. And if you contact Araknerd, we’ll keep a record of your request in order to help solve any issues you might be facing.</p>
                <li> <h6>Protect Araknerd, our users and the public.</h6> </li>
                <p>We use information to help improve the safety and reliability of our services. This includes detecting, preventing, and responding to fraud, abuse, security risks, and technical issues that could harm Araknerd, our users, or the public.</p>
            </ul>
        </div>
    </section>
    <section id="your-privacy-controls">
        <div class="container" style={{paddingInline: "40px", marginTop: "40px"}}>
            <h5>YOUR PRIVACY CONTROLS</h5>
            <p>You have choices regarding the information we collect and how it's used.</p>
            <p>This section describes key controls for managing your privacy across our services. We provide an opportunity to review and adjust important privacy settings. In addition to these controls, we also offer specific privacy settings in our products.</p>
            <h6>Managing, reviewing, and updating your information</h6>
            <p>When you are signed in, you can always review and update information by visiting the products you use. We also built a place for you to review and control information saved in your Araknerd Account.</p>
        </div>
    </section>

    <section id="keeping-your-information-secure">
        <div class="container" style={{paddingInline: "40px", marginTop: "40px"}}>
            <p>We build security into our services to protect your information.</p>
            <p>All Araknerd products are built with strong security features that continuously protect your information. The insights we gain from maintaining our products help us detect and automatically block security threats from ever reaching you. And if we do detect something risky that we think you should know about, we’ll notify you and help guide you through steps to stay better protected.</p>
            <p>We work hard to protect you and Araknerd from unauthorized access, alteration, disclosure, or destruction of information we hold, including:</p>
            <ul className="keep-info" style={{marginLeft: "50px"}}>
                <li>We use encryption to keep your data private while in transit.</li>
                <li>We offer a range of security features, like Security Checkup, and 2-step verification to help you protect your account</li>
                <li>We review our information collection, storage, and processing practices, including physical security measures, to prevent unauthorized access to our systems</li>
                <li>We restrict access to personal information to Araknerd employees, contractors, and agents who need that information in order to process it. Anyone with this access is subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.</li>
            </ul>
        </div>
    </section>

    <section id="deleting-your-information">
        <div class="container" style={{paddingInline: "40px", marginTop: "40px"}}>
            <h5>DELETING YOUR INFORMATION</h5>
            <p>You can delete your information from your Araknerd Account at any time.</p>
            <p>To delete your information, you can:
                <ul className='delete-info' style={{marginLeft: "50px"}}>
                    <li>Delete your content from specific Araknerd products</li>
                    <li>Delete specific Araknerd products, including your information associated with those products</li>
                    <li>Delete entire Araknerd account</li>
                </ul>
            </p>
        </div>
    </section>
    <section id="retaining-your-information">
        <div class="container" style={{paddingInline: "40px", marginTop: "40px"}}>
            <h5>RETAINING YOUR INFORMATION</h5>
            <p>We retain the data we collect for different periods of time depending on what it is, how we use it, and how you configure your settings:</p>
                <ul className='retain-info' style={{marginLeft: "50px"}}>
                    <li>Some data you can delete whenever you like, such as the content you create or upload. You can also delete activity information saved in your account, or choose to have it deleted automatically after a set period of time</li>
                    <li>We keep some data until you delete your Araknerd Account, such as information about how often you use our services.</li>
                    <li>And some data we retain for longer periods of time when necessary for legitimate business or legal purposes, such as security, fraud and abuse prevention, or financial record-keeping.</li>
                </ul>
            <p>When you delete data, we follow a deletion process to make sure that your data is safely and completely removed from our servers or retained only in anonymized form. We try to ensure that our products protect information from accidental or malicious deletion. Because of this, there may be delays between when you delete something and when copies are deleted from our active and backup systems.</p>
        
        </div>
    </section>
    <section id="compliance">
        <div class="container" style={{paddingInline: "40px", marginTop: "40px"}}>
            <h5>COMPLIANCE AND COOPERATION WITH REGULATORS</h5>
            <p>We regularly review this Privacy Policy and make sure that we process your information in ways that comply with it.</p>
        </div>
    </section>
    <section id="about-this-policy" style={{paddingBottom: "100px"}}>
        <div class="container" style={{paddingInline: "40px", marginTop: "40px"}}>
            <h5>ABOUT THIS POLICY</h5><br/>
            <h6>When this policy applies</h6>
            <p>This Privacy Policy applies to all of the products offered by Araknerd and its affiliates, including Quickpost mobile application,  YEXP Accounting system, Eschool and Epos web platform. This Privacy Policy doesn’t apply to products that have separate privacy policies that do not incorporate this Privacy Policy.</p>
            <p>This privacy policy does not apply to:
                <ul className="about-policy" style={{marginLeft: "50px"}}>
                    <li>The information practices of other companies and organizations that advertise our products.</li>
                    <li>Products offered by other companies or individuals, including products or sites they offer that may include Araknerd products to which the policy applies.</li>
                   
                </ul>
            </p>    
            <h6>Changes to this policy</h6>
            <p>We change this Privacy Policy from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent. We always indicate the date the last changes were published and we offer access to archived versions for your review. If changes are significant, we’ll provide a more prominent notice (including, for certain products, email notification of Privacy Policy changes).</p>
        
        </div>
    </section>
  <Footer/>
    </div>
  )
}

export default Privacy