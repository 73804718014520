import React from "react";
import Home from "./Pages/Home";
// import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

import { Route, BrowserRouter as Router, Routes as Switch} from 'react-router-dom'

import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Pricing from "./Pages/Pricing";
import Quickpost from "./Pages/Products/Quickpost";
import Yexp from "./Pages/Products/Yexp";
import Eschool from "./Pages/Products/Eschool";
import Epos from "./Pages/Products/Epos";
import Privacy from "./Pages/Privacy";

const App = ()=> {
  return (
  <Router forceRefresh={false}>
  <Switch>
    <Route path="/" element={<Home/>} />
    <Route path="/about" element={ <About />} onChange={()=>alert("Link updated")}/>
    <Route path="/contact" element={ <Contact />}/>
    <Route path="/pricing" element={ <Pricing />}/>
    <Route path="/quickpost" element={<Quickpost />}/>
    <Route path="/yexp" element={<Yexp />}/>
    <Route path="/eschool" element={<Eschool />}/>
    <Route path="/epos" element={<Epos />}/>
    <Route path="/privacy" element={<Privacy/>}/>
  </Switch>
  </Router>
  );
}

export default App;
