const dictionary={

    email:"support@araknerd.com",
    companyName:"ARAKNERD",
    fullCoName:"ARAKNERD Company Ltd",
    contact:"0704722190",
    heroText:"We have capacity to build software business solutions of any complexity and size, adapting to the needs of our clients.",
    servicesText:"Our software solutions have been built with a vast programming experience making them highly reliable, scalable and secure. At ARAKNERD, We have a well maintained framework for building robust applications based on a 3 tier architecture. The software development trainees at ARAKNERD get a first class hands on learning experience with 99% chances of job connection.",
    araknerdLibText:" php framework built with over 10 years of experience! Developers can now spend less time worrying about code base structure and more time to concentrate on the project logic.",
    araknerdLibText2: "Build secure APIs with a highly portable and managable framework. Join our community and together we can take AraknerdLib to another level."

}


export default dictionary;